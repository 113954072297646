import Vue from 'vue'
import { getUserId } from '../../utils/data-check'
import { alert as $alert } from '../../utils/alert'
import orderApis from '../../apis/order'
import { getCartTotal } from '@/utils/order'
import { getTotalName } from '@/store/modules/helper'

export default {
	namespaced: true,
	state: {
		token: '',
		xsrfToken: '',
		userName: '',
		userId: null,
		storeId: null,
		storeName: '',
		takeway: 'takeaway',
		isMemoryAccount: false,
		account: '',
		cart: {
			guest: {},
		},
		router: '',
		userPhone: '',
		guestId: null,
		checkLoginFrom: '',
		userAddress: '',
		userUniformNumber: '',
		// 是否顯示WhatsEat優惠卷
		showWhatsEatCoupon: true,
		operationHasFailed: false,
	},
	getters: {
		currCart(state) {
			return state.cart[getUserId(state.userId)][state.storeId]
		},
		cartTotal(state, getters) {
			return getCartTotal(getters.currCart)
		},
		productLength(state, getters) {
			const { currCart } = getters
			return currCart ? currCart.reduce((p, e) => (e.count || 0) + p, 0) : 0
		},
	},
	mutations: {
		SET_TOKEN(state, value) {
			state.token = value
		},
		SET_XSRF_TOKEN(state, value) {
			state.xsrfToken = value
		},
		SET_STORE(state, store) {
			state.storeId = store.storeId
			state.storeName = store.storeName
		},
		SET_ROUTER(state, value) {
			state.router = value
		},
		SET_USER_NAME(state, value) {
			state.userName = value
		},
		SET_USER_PHONE(state, value) {
			state.userPhone = value
		},
		SET_USER_ADDRESS(state, value) {
			state.userAddress = value
		},
		SET_USER_UNIFORM_NUMBER(state, value) {
			state.userUniformNumber = value
		},
		SET_USER_ID(state, value) {
			state.userId = value
		},
		SET_GUEST_ID(state, value) {
			state.guestId = value
		},
		SET_ACCOUNT(state, value) {
			state.account = value
		},
		SET_IS_MEMORY_ACCOUNT(state, value) {
			state.isMemoryAccount = value
		},
		SET_CHECK_LOGINFROM(state, val) {
			state.checkLoginFrom = val
		},
		SET_SHOW_WHATS_EAT_COUPON(state, val) {
			state.showWhatsEatCoupon = val
		},
		SET_OPERATION_HAS_FAILED(state, val) {
			state.operationHasFailed = val
		},
		CHECK_CART_AND_SET($) {
			const { cart, userId, storeId } = $
			const newUserId = getUserId(userId)
			if (storeId) {
				if (!cart[newUserId]) {
					Vue.set(cart, newUserId, {})
				}
				if (!cart[newUserId][storeId]) {
					Vue.set(cart[newUserId], storeId, [])
				}
			}
		},
		SET_CART_ITEM($, val) {
			this.commit('user/CHECK_CART_AND_SET')
			$.cart[getUserId($.userId)][$.storeId] = val
		},
		SET_GUEST_CART($, val) {
			this.commit('user/CHECK_CART_AND_SET')
			$.cart['guest'][$.storeId] = val
		},
		ADD_CART_ITEM($, val) {
			this.commit('user/CHECK_CART_AND_SET')
			$.cart[getUserId($.userId)][$.storeId].push(val)
		},
		REMOVE_CART_ITEM($, { index }) {
			this.commit('user/CHECK_CART_AND_SET')
			$.cart[getUserId($.userId)][$.storeId].splice(index, 1)
		},
		UPDATE_CART_ITEM($, { data, index }) {
			this.commit('user/CHECK_CART_AND_SET')
			const { cart, userId, storeId } = $
			const storeCart = cart[getUserId(userId)][storeId]
			cart[getUserId(userId)][storeId] = [
				...storeCart.slice(0, index),
				data,
				...storeCart.slice(index + 1, storeCart.length),
			]
		},
		SET_CART($, val) {
			$.cart = val
		},
		CLEAR_CART($) {
			$.cart[getUserId($.userId)][$.storeId] = []
			this.commit('user/CHECK_CART_AND_SET')
		},
		// 登入 A+B 及 Detail 購物車新增
		LOGIN_AMOUNT_CART(
			$,
			{ cartItems, callback, isResetCart = true, editIndex = null } = {},
		) {
			const { cart, userId, storeId } = $
			const guestStore = cartItems ? cartItems : cart.guest[storeId]
			if (guestStore.length) {
				const userCart = cart[getUserId(userId)]
				const storeCart = userCart ? userCart[storeId] : []
				const storeCartLen = storeCart.length
				if (storeCartLen === 0 && guestStore.length === 1) {
					editIndex == null && this.commit('user/ADD_CART_ITEM', guestStore[0])
				} else {
					guestStore.forEach(guItem => {
						if (storeCartLen === 0) {
							editIndex == null && this.commit('user/ADD_CART_ITEM', guItem)
						} else {
							let isAddItem = false
							for (let scI = 0; scI < storeCartLen; scI++) {
								const scItem = storeCart[scI]
								const newItem = {
									...scItem,
									count: scItem.count + guItem.count,
								}

								if (
									scItem.itemId === guItem.itemId &&
									scItem.itemRemark === guItem.itemRemark
								) {
									const guName = getTotalName(guItem)
									const scName = getTotalName(scItem)
									if (guName === scName) {
										this.commit('user/UPDATE_CART_ITEM', {
											data: editIndex !== scI ? newItem : cartItems[0],
											index: scI,
										})
										if (editIndex != null && editIndex !== scI) {
											this.commit('user/REMOVE_CART_ITEM', { index: editIndex })
										}
										isAddItem = false
										break
									} else {
										if (scI === storeCart.length - 1) {
											isAddItem = true
										}
									}
								} else {
									isAddItem = true
								}
							}

							if (editIndex == null) {
								if (isAddItem) {
									this.commit('user/ADD_CART_ITEM', guItem)
								}
							} else {
								if (isAddItem) {
									this.commit('user/UPDATE_CART_ITEM', {
										data: cartItems[0],
										index: editIndex,
									})
								}
							}
						}
					})
				}
				callback && callback()
			}
			isResetCart && this.commit('user/SET_GUEST_CART', [])
		},
	},
	actions: {
		// 購物車數據比對調換數據
		compareCart({ commit, state }) {
			this.commit('user/CHECK_CART_AND_SET')
			const statusEnum = {
				EQUALS: 0, // 比對結果相仿
				NODATA: 0, // 購物車無資料
				NOT_EQUALS: 1, // 資料有異動
				ERROR: 2, // 語法或api錯誤
			}
			const { userId, storeId, cart } = state
			const gUserId = getUserId(userId)
			const storeCart = cart[gUserId][storeId]
			const productIds = new Set(storeCart.map(cartItem => cartItem.itemId))
			const productIdsToArray = [...productIds]
			if (productIdsToArray.length === 0)
				return { message: '購物車無資料', status: statusEnum.NODATA }
			return new Promise(async (reslove, reject) => {
				try {
					const res = (
						await orderApis.getItems({
							itemIdList: productIdsToArray,
							storeId: storeId,
						})
					).data

					const objRes = res.reduce((lastVal, item) => {
						return { ...lastVal, [item.itemId]: item }
					}, {})

					const newCart = {
						...cart,
						[gUserId]: {
							...cart[gUserId],
							[storeId]: [],
						},
					}

					let changedCartItems = new Set()
					let status = statusEnum.EQUALS

					function setStatus(_status, _itemName) {
						changedCartItems.add('"' + _itemName + '"')
						if (_status > status) {
							status = _status
						}
					}

					storeCart.forEach(storeCartItem => {
						const compareCart = objRes[storeCartItem.itemId]
						if (changedCartItems) {
							let isBreakComboLoop = false
							if (
								storeCartItem.upgradeListId &&
								storeCartItem.upgradeListId !== 'null'
							) {
								const sameCompareUpgrade = compareCart.upgradeList.find(
									compareUpgrade =>
										String(compareUpgrade.upgradeId) ===
										storeCartItem.upgradeListId,
								)
								if (sameCompareUpgrade) {
									if (
										sameCompareUpgrade.name === storeCartItem.upgradeName &&
										sameCompareUpgrade.price === storeCartItem.upgradePrice
									) {
										storeCartItem.upgradeSelect.forEach(upgradeContent => {
											const sameUpgradeContent =
												sameCompareUpgrade.upgradeContentList?.find(
													compareContent =>
														compareContent.upgradeContentId ===
														upgradeContent.upgradeContentId,
												)
											if (sameUpgradeContent) {
												if (sameUpgradeContent.name === upgradeContent.name) {
													upgradeContent.upgradeContentDetails.forEach(d => {
														const upgradeContentDetail = d.upgradeContentDetail
														const sameUpgradeContentDetail =
															sameUpgradeContent.upgradeContentDetailList?.find(
																a =>
																	a.upgradeContentDetailId ===
																	upgradeContentDetail?.upgradeContentDetailId,
															)

														if (sameUpgradeContentDetail) {
															if (
																sameUpgradeContentDetail.categoryId ===
																	upgradeContentDetail.categoryId &&
																sameUpgradeContentDetail.itemStatus ===
																	upgradeContentDetail.itemStatus &&
																sameUpgradeContentDetail.itemId ===
																	upgradeContentDetail.itemId &&
																sameUpgradeContentDetail.itemName ===
																	upgradeContentDetail.itemName &&
																sameUpgradeContentDetail.price ===
																	upgradeContentDetail.price
															) {
																upgradeContentDetail?.optionList.forEach(
																	option => {
																		const sameUpgradeOption =
																			sameUpgradeContentDetail?.optionList?.find(
																				a => a.id === option.id,
																			)
																		if (sameUpgradeOption) {
																			if (
																				option.name ===
																					sameUpgradeOption.name &&
																				option.required ===
																					sameUpgradeOption.required &&
																				option.type ===
																					sameUpgradeOption.type &&
																				option.updateStatus ===
																					sameUpgradeOption.updateStatus
																			) {
																				option?.optionDetailList.forEach(
																					optionDetail => {
																						if (option.type) {
																							const sameOptionDetail =
																								option.count?.find(
																									v =>
																										v ===
																										String(optionDetail.id),
																								)

																							if (sameOptionDetail) {
																								sameUpgradeOption.optionDetailList?.forEach(
																									_optionDetail => {
																										const _sameOptionDetail =
																											option.count?.find(
																												v =>
																													v ===
																													String(
																														_optionDetail.id,
																													),
																											)
																										if (_sameOptionDetail) {
																											if (
																												_optionDetail.name ===
																													optionDetail.name &&
																												_optionDetail.price ===
																													optionDetail.price
																											) {
																												//驗證成功
																											} else {
																												optionTypeCondition = true
																											}
																										}
																									},
																								)
																							}
																						} else {
																							if (
																								String(optionDetail.id) ===
																								option.count
																							) {
																								const sameOptionDetail =
																									sameUpgradeOption.optionDetailList.find(
																										a =>
																											a.id ===
																											String(optionDetail.id),
																									)
																								if (sameOptionDetail) {
																									if (
																										sameOptionDetail.name ===
																											optionDetail.name &&
																										sameOptionDetail.price ===
																											optionDetail.price
																									) {
																										//驗證成功
																									} else {
																										optionTypeCondition = true
																									}
																								}
																							}
																						}
																					},
																				)
																			} else {
																				isBreakComboLoop = true
																			}
																		} else {
																			isBreakComboLoop = true
																		}
																	},
																)
															} else {
																isBreakComboLoop = true
															}
														} else {
															isBreakComboLoop = true
														}
													})
												} else {
													isBreakComboLoop = true
												}
											} else {
												isBreakComboLoop = true
											}
										})
									} else {
										isBreakComboLoop = true
									}
								} else {
									isBreakComboLoop = true
								}
							}

							if (isBreakComboLoop) {
								setStatus(statusEnum.NOT_EQUALS, storeCartItem.itemName)
								return
							}

							//驗證口味跟名子
							let optionTypeCondition = false

							compareCart?.optionList.forEach(option => {
								const sameOption = storeCartItem?.optionList?.find(
									a => a.id === option.id,
								)
								if (sameOption) {
									if (
										option.name === sameOption.name &&
										option.required === sameOption.required &&
										option.type === sameOption.type &&
										option.updateStatus === sameOption.updateStatus
									) {
										option?.optionDetailList.forEach(optionDetail => {
											if (option.type) {
												const sameOptionDetail = option.count?.find(
													v => v === String(optionDetail.id),
												)

												if (sameOptionDetail) {
													sameOption.optionDetailList?.forEach(
														_optionDetail => {
															const _sameOptionDetail = option.count?.find(
																v => v === String(_optionDetail.id),
															)
															if (_sameOptionDetail) {
																if (
																	_optionDetail.name === optionDetail.name &&
																	_optionDetail.price === optionDetail.price
																) {
																	//驗證成功
																} else {
																	optionTypeCondition = true
																}
															}
														},
													)
												}
											} else {
												if (String(optionDetail.id) === option.count) {
													const sameOptionDetail =
														sameOptionDetail.optionDetailList.find(
															a => a.id === String(optionDetail.id),
														)
													if (sameOptionDetail) {
														if (
															sameOptionDetail.name === optionDetail.name &&
															sameOptionDetail.price === optionDetail.price
														) {
															//驗證成功
														} else {
															optionTypeCondition = true
														}
													}
												}
											}
										})
									} else {
										optionTypeCondition = true
									}
								} else {
									optionTypeCondition = option.status !== false
								}
							})

							if (compareCart?.itemName != null && !optionTypeCondition) {
								// 名字及價格更新
								if (
									compareCart.itemName !== storeCartItem.itemName ||
									compareCart.price !== storeCartItem.itemPrice
								) {
									return setStatus(
										statusEnum.NOT_EQUALS,
										storeCartItem.itemName,
									)
								}
								const item = {
									...storeCartItem,
								}

								newCart[gUserId][storeId].push(item)
							} else {
								setStatus(statusEnum.NOT_EQUALS, storeCartItem.itemName)
							}
						} else {
							setStatus(statusEnum.NOT_EQUALS, storeCartItem.itemName)
						}
					})

					commit('SET_CART', newCart)

					reslove({
						message: `店家有異動 ${[...changedCartItems].join(
							'、',
						)} 餐點內容！請重新選購！`,
						status,
					})
				} catch (err) {
					$devLog('error', err)
					$alert('網頁操作逾期，請刷新頁面後重新進行點餐', 'danger')
					commit('SET_CART_ITEM', [])
					commit('SET_OPERATION_HAS_FAILED', true)

					reject({
						message: '網頁操作逾期，請刷新頁面後重新進行點餐',
						status: statusEnum.ERROR,
					})
				}
			})
		},
	},
}
